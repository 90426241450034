import React from 'react'
import { useState, useEffect } from 'react';
import { IoCheckmarkCircle, IoEllipse } from 'react-icons/io5';
import { ReactComponent as ConfirmInvitationSent } from '../../static/assets/invitation-sent-confirm.svg';
import LoadingSymbolRipple from '../gadgets/LoadingSymbolRipple'
import Api from '../../common/APIUtils';

import ModalSimpleHeader from './simple/ModalSimpleHeader';
import ModalSimpleSingleButton from './simple/ModalSimpleSingleButton';
import ModalToolMultiSelectWorkspaceDropdownWithSearch from './tools/ModalToolMultiSelectWorkspaceDropdownWithSearch';
import ModalToolSingleSelectRoleDropdownWithSearch from './tools/ModalToolSingleSelectRoleDropdownWithSearch';

export default function ModalAddNetworkWorkspaceUser({hideModal, prePopulatedWorkspaceIds, networkId, network, networkCapacity}) {
    const api = new Api()
    const [emailAddressString, setEmailAddressString] = useState(() => { return "" })
    const [parsedEmailAddresses, setParsedEmailAddresses] = useState(() => { return [] })
    const [selectedWorkspaceIds, setSelectedWorkspaceIds] = useState(() => { return [] })
    const [isLoadingModal, setIsLoadingModal] = useState(() => { return true })
    const [selectedTeamId, setSelectedTeamId] = useState(() => { return false })
    const [selectedRoleId, setSelectedRoleId] = useState(() => { return false })
    const [networkData, setNetworkData] = useState(() => { return false })
    const [displayAllWorkspaces, setDisplayAllWorkspaces] = useState(() => { return false })
    const [page, setPage] = useState(() => { return 1 })
    const [isInvitable, setIsInvitable] = useState(() => { return false })
    const [permissionScope, setPermissionScope] = useState(() => { return false })
    const fetchUserInvitationOptions = () => {
        if (!networkId) return hideModal()
        api.getNetworkInvitationData(networkId)
        .then((res) => {
            setNetworkData(res.data)
            setIsLoadingModal(false)
        })
        .catch((err) => console.log(err))
    }
    const sendInvitations = () => {
        let payload = {
            network_id: networkId,
            user_emails: parsedEmailAddresses,
            permission_scope: permissionScope,
            workspace_ids: selectedWorkspaceIds,
            team_id: selectedTeamId,
            role_id: selectedRoleId
        }
        api.inviteUsersToNetwork(payload)
        .then((res) => {
            if (res.status !== 200) return setPage(3)
            setPage(2)
        })
        .catch((err) => {
            console.log(err)
            setPage(3)
        })
    }
    const getRoleOptions = () => {
        if (!networkData) return []
        if (!networkData.roles) return []
        return networkData.roles
    }
    const getTeamOptions = () => {
        if (!networkData) return []
        if (!networkData.teams) return []
        return networkData.teams
    }
    const getWorkspaceOptions = () => {
        if (!networkData) return []
        if (!networkData.workspaces) return []
        return networkData.workspaces
    }
    const addWorkspaceToSelected = (workspaceId) => {
        if (selectedWorkspaceIds.includes(workspaceId)) return
        let updatedWorkspaceIds = Array.from(selectedWorkspaceIds)
        updatedWorkspaceIds.push(workspaceId)
        setSelectedWorkspaceIds(updatedWorkspaceIds)
    }
    const removeWorkspaceFromSelected = (workspaceId) => {
        if (!selectedWorkspaceIds.includes(workspaceId)) return
        let updatedWorkspaceIds = Array.from(selectedWorkspaceIds)
        const index = updatedWorkspaceIds.indexOf(workspaceId)
        if (index < 0) return
        updatedWorkspaceIds.splice(index, 1)
        setSelectedWorkspaceIds(updatedWorkspaceIds)
    }
    const parseEmails = () => {
        const regex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
        const emailSet = new Set();
        let emailMatch;
        while ((emailMatch = regex.exec(emailAddressString)) !== null) {
            emailSet.add(emailMatch[0]);
        }
        let emailArray = Array.from(emailSet)
        try {
            const networkUse = network.network_users.length
            const allowedInvites = networkCapacity - networkUse
            if (emailArray.length > allowedInvites) emailArray = emailArray.slice(0, allowedInvites)
        } catch (error) {
            emailArray = Array.from(emailSet)
        }
        setParsedEmailAddresses(emailArray)
    }
    const formatInvitationMessageWorkspaces = () => {
        if (!permissionScope === "workspace") return "An email has been sent to their email address with next steps."
        if (!parsedEmailAddresses || parsedEmailAddresses.length === 0) return "An email has been sent to their email address with next steps."
        const numAddresses = parsedEmailAddresses.length;
        let copyParsedEmails = Array.from(parsedEmailAddresses)
        let message;
        switch (numAddresses) {
          case 1:
            message = `An email has been sent to ${copyParsedEmails[0]} with next steps`;
            break;
          case 2:
            message = `An email has been sent to ${copyParsedEmails[0]} and ${copyParsedEmails[1]} with next steps`;
            break;
        case 3:
            message = `An email has been sent to ${copyParsedEmails[0]}, ${copyParsedEmails[1]}, and ${copyParsedEmails[2]} with next steps`;
            break;
          default:
            const remaining = numAddresses - 3;
            message = `An email has been sent to ${copyParsedEmails[0]}, ${copyParsedEmails[1]}, ${copyParsedEmails[2]}, and ${remaining} others with next steps`;
            break;
        }
        return message;
    }
    const formatInvitationMessageTeams = () => {
        try {
            if (!parsedEmailAddresses || parsedEmailAddresses.length === 0 || !selectedTeamId) return false

            var team = networkData.teams.find(t => t._id === selectedTeamId)
            if (!team) return false // no team found by the selected ID

            const foundMembership = networkData.memberships.find(m => m.member_id === selectedTeamId)
            if (!foundMembership) return `They will be added to ${team.name} which cannot currently access any workspaces.` // Membership not found
            const roleId = foundMembership.role_id

            const foundRole = networkData.roles.find(r => r._id === roleId)
            if (!foundRole) return `They will be added to ${team.name} which currently gives them limited permissions` // the team has no role
            const roleName = foundRole.name

            // Parse workspace names from the networkData
            let workspaceNames = []
            if (team.workspace_ids) {
                for (let i = 0; i < team.workspace_ids.length; i++) {
                    const foundWorkspace = networkData.workspaces.find(w => w._id === team.workspace_ids[i])
                    if (foundWorkspace) workspaceNames.push(foundWorkspace.name)
                }
            }
            
            if (!roleName || !roleId || !team || workspaceNames.length === 0) return false
            let message;
            switch (workspaceNames.length) {
                case 1:
                    message = `This user will have access to ${workspaceNames[0]}, and will be invited as a ${roleName}`
                    return <div>{message}</div>
                case 2:
                    message = `This user will have access to ${workspaceNames[0]} and ${workspaceNames[1]}, and will be invited as a ${roleName}`
                    return <div>{message}</div>
                case 3:
                    message = `This user will have access to ${workspaceNames[0]}, ${workspaceNames[1]}, and ${workspaceNames[2]} and will be invited as a ${roleName}`
                    return <div>{message}</div>
                case 4:
                    return (
                        <div>
                            {!displayAllWorkspaces &&
                            <>This user will have access to {workspaceNames[0]}, {workspaceNames[1]}, {workspaceNames[2]} and <span className="modal-mgmt-expandable-review-text" onClick={() => setDisplayAllWorkspaces(true)}>1 other workspace</span> and will be invited as a {roleName}</>}
                            {displayAllWorkspaces &&
                            <>This user will have access to {workspaceNames.join(", ")} and will be invited as a {roleName}</>}
                        </div>
                    )
                default:
                    const remaining = workspaceNames.length - 3
                    return (
                        <div>
                            {!displayAllWorkspaces &&
                            <>This user will have access to {workspaceNames[0]}, {workspaceNames[1]}, {workspaceNames[2]} and <span className="modal-mgmt-expandable-review-text" onClick={() => setDisplayAllWorkspaces(true)}>{remaining} other workspaces</span> and will be invited as a {roleName}</>}
                            {displayAllWorkspaces &&
                            <>This user will have access to {workspaceNames.join(", ")} and will be invited as a {roleName}</>}
                        </div>
                    )
            }
        } catch (error) {
            console.log(error)
            if (team.name) return `This user will be added to ${team.name}`
            return false
        }
    }
    useEffect(() => {
        if (!permissionScope) {
            setIsInvitable(false)
            return
        }
        if (parsedEmailAddresses.length === 0) {
            setIsInvitable(false)
            return
        }
        if (permissionScope === "workspace" && selectedWorkspaceIds.length === 0) {
            setIsInvitable(false)
            return
        }
        if (permissionScope === "workspace" && !selectedRoleId) {
            setIsInvitable(false)
            return
        }
        if (permissionScope === "team" && !selectedTeamId) {
            setIsInvitable(false)
            return
        }
        setIsInvitable(true)
    }, [permissionScope, selectedWorkspaceIds, selectedRoleId, selectedTeamId, parsedEmailAddresses])
    useEffect(() => {
        parseEmails()
    // eslint-disable-next-line
    },[emailAddressString])
    useEffect(() => {
        setDisplayAllWorkspaces(false)
    }, [selectedTeamId])
    useEffect(() => {
        if (prePopulatedWorkspaceIds) setSelectedWorkspaceIds(prePopulatedWorkspaceIds)
        if (!networkId) return
        fetchUserInvitationOptions()
    // eslint-disable-next-line
    }, [])
    return (
        <div className="modal-mgmt-background-overlay">
            <div className="modal-mgmt-container">
                <ModalSimpleHeader title="New users unavailable" hideModal={hideModal} />
                <div className="modal-mgmt-sections">
                <div className="modal-mgmt-section">
                        <h3>We are currently making drastic changes to the platform. New accounts are not available at this time.</h3>
                    </div>
                    <ModalSimpleSingleButton isValid={true} buttonText="Okay" onclick={hideModal} />
                </div>
            </div>
        </div>
    )
}
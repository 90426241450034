import React from 'react'
import { ReactComponent as Logo } from '../../static/assets/logov1-basicblue.svg';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import { TbMenuDeep } from "react-icons/tb";

export default function UnauthenticatedHeader({authenticated, nonSticky}) {
    let history = useHistory();
    const location = useLocation()
    const [nav, setNav] = useState(() => { return "home" })
    const [isShowingMobileMenu, setIsShowingMobileMenu] = useState(() => { return false })
    const updateNav = () => {
        try {
            if (!location) return
            setIsShowingMobileMenu(false)
            if (location.pathname === "/pricing") return setNav("pricing")
            if (location.pathname === "/about") return setNav("about")
            if (location.pathname === "/blog") return setNav("blog")
            // if (location.pathname === "/use-cases") return setNav("uses")
            return setNav("home")
        } catch (error) {
            return setNav("home")
        }
    }
    const navigateFromPageMobile = (proposedPageName) => {
        setIsShowingMobileMenu(false)
        history.push(proposedPageName)
    }
    // eslint-disable-next-line
    const getNavClass = (baseNav) => {
        if (baseNav === nav) return "unauthenticated-header-nav-sel"
        return ""
    }
    useEffect(() => {
        updateNav()
    // eslint-disable-next-line
    }, [location])
    return (
        <div className="unauthenticated-header-outer" style={nonSticky ? {position: "relative"} : {}}>
            <div className="unauthenticated-header">
                <div className="unauthenticated-header-side">
                    <div className="unauthenticated-header-logo" onClick={() => { history.push('/welcome')}}><Logo/></div>
                </div>
                <div className="unauthenticated-header-middle">
                    {/* <span onClick={() => { history.push('/welcome')}} className={`unauthenticated-header-nav`}>Home</span> */}
                    {/* <span onClick={() => { history.push('/pricing')}} className={`unauthenticated-header-nav ${getNavClass("pricing")}`}>Pricing</span> */}
                    {/* <span onClick={() => { history.push('/blog')}} className={`unauthenticated-header-nav ${getNavClass("blog")}`}>Blog</span> */}
                    {/* <span onClick={() => { history.push('/use-cases')}} className={`unauthenticated-header-nav ${getNavClass("uses")}`}>Use Cases</span> */}
                </div>
                {!authenticated &&
                <div className="unauthenticated-header-side util-row-reverse unauthenticated-header-side-desktop" style={{minWidth: "220px"}}>
                    {/* <span onClick={() => { history.push('/register')}} className="unauthenticated-header-button-start">Get started</span> */}
                    <span onClick={() => { history.push('/login')}} className="unauthenticated-header-button-login">Log In</span>
                </div>}
                {authenticated && <div className="unauthenticated-header-side"></div> }

                {/* MOBILE MENU */}
                <div className="unauthenticated-header-side-mobile">
                    <div className="unauthenticated-header-side-icon" onClick={() => setIsShowingMobileMenu(!isShowingMobileMenu)}><TbMenuDeep/></div>
                    {isShowingMobileMenu &&
                    <div className="unauthenticated-header-side-mobile-menu">
                        <span onClick={() => navigateFromPageMobile("/register")}>Get started</span>
                        <span onClick={() => navigateFromPageMobile("/login")}>Login</span>
                        {/* <span onClick={() => navigateFromPageMobile("/pricing")}>Pricing</span> */}
                        {/* <span onClick={() => navigateFromPageMobile("/blog")}>Blog</span> */}
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import { useHistory } from 'react-router-dom';

export default function LandingFooter() {
    let history = useHistory();
    return (
        <div className="lnding-footer">
            <div className="lnding-footer-sections">
                <div className="lnding-footer-section">
                    <div className="lnding-footer-section-heading">Tetheros</div>
                    <div className="lnding-footer-links">
                        <span onClick={() => history.push('/login')}>Log In</span>
                        {/* <span onClick={() => history.push('/register')}>Register</span> */}
                        <span><a href="https://tetheros.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a></span>
                    </div>
                </div>
            </div>
            <div className="lnding-footer-sections">
                <div className="lnding-footer-section">
                    <div className="lnding-footer-section-heading">Support</div>
                    <div className="lnding-footer-links">
                        <span><a href="https://docs.tetheros.com/bugs/report" target="_blank" rel="noreferrer">Report Bug</a></span>
                        <span><a href="https://docs.tetheros.com/" target="_blank" rel="noreferrer">Documentation</a></span>
                        <span><a href="https://docs.tetheros.com/docs/roadmap" target="_blank" rel="noreferrer">Roadmap</a></span>
                        <span><a href="mailto:support@tetheros.com">Contact</a></span>
                    </div>
                </div>
                <div className="lnding-footer-section">
                    <div className="lnding-footer-section-heading">Company</div>
                    <div className="lnding-footer-links">
                        <span onClick={() => history.push('/pricing')}>Pricing</span>
                        <span><a href="https://www.linkedin.com/company/tetheros/" target="_blank" rel="noreferrer">LinkedIn</a></span>
                        <span onClick={() => history.push('/blog')}>Blog</span>
                    </div>
                </div>
                <div className="lnding-footer-section">
                    <div className="lnding-footer-section-heading">Have a question?</div>
                    <div className="lnding-footer-links">
                        <span><a href="mailto:hello@tetheros.com">hello@tetheros.com</a></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

import { useLocation, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react'
import '../styles/forms.css';
import ToastText from './gadgets/ToastText';
import { isValidEmailAddress } from '../common/Helpers';
import DataManager from '../common/DataManager';
import Api from '../common/APIUtils'

import { registerForTetherosLinkedIn } from "../common/Api";
import { IoMailOutline } from "react-icons/io5";

import UnauthenticatedHeader from './header/UnauthenticatedHeader';

export default function Register({refreshAuthStatus}) {
    const api = new Api()
    const query = new URLSearchParams(useLocation().search)
    const plan = query.get('plan')
    const annual = query.get('annual') === 'true'
    let history = useHistory();
    let dataManager = DataManager.getInstance();
    const [registrationPage, setRegistrationPage] = useState(() => { return "landing" });
    const [firstName, setFirstName] = useState(() => { return "" });
    const [lastName, setLastName] = useState(() => { return "" });
    const [emailAddress, setEmail] = useState(() => { return "" });
    const [password, setPassword] = useState(() => { return "" });
    const [passwordCheck, setPasswordCheck] = useState(() => { return "" });
    const [canSubmit, setCanSubmit] = useState(() => { return false })
    const [toastText, setToastText] = useState(() => { return "" })
    const [showToast, setShowToast] = useState(() => { return false })
    const [currentIndex, setCurrentIndex] = useState(0);
    const backgroundShades = [
        [20, 82, 148],
        [56, 74, 107],
        [42, 115, 174],
        [159, 106, 219],
        [252, 99, 107],
        [255, 185, 1],
    ];
    const getContinueButtonStyle = () => {
        if (canSubmit) return {}
        return { backgroundColor: "grey" }
    }
    const registerWithLinkedIn = () => {
        const payload = {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            emailAddress: emailAddress.toLowerCase(),
            password: password,
            plan_name: plan,
            is_annual: annual
        }
        localStorage.setItem("gPlanInterest", plan)
        localStorage.setItem("gPlanAnnualInterest", String(annual))
        registerForTetherosLinkedIn(payload)
        .then((res) => {
            console.log(res)
        })
        .catch((err) => {
            console.log(err)
            // handle error
        })
    }
    const registerUser = () => {
        if (!canSubmit) {
            // generate the right toast message
            if (!isValidEmailAddress(emailAddress)) {
                setToastText("Invalid email address")
            } else if (!password) {
                setToastText("Enter a password to continue")
            } else if (password.length < 5) {
                setToastText("Password must be at least 5 characters long")
            } else if (password !== passwordCheck) {
                setToastText("Passwords don't match")
            }
            setShowToast(true)
            return
        }
        const payload = {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            emailAddress: emailAddress.toLowerCase(),
            password: password,
            plan_name: plan,
            is_annual: annual
        }
        api.registerNewUser(payload)
        .then( (res) => {
            refreshAuthStatus();
            if (res.status === 200) {
                dataManager.setAuth(true);
                history.push('/home');
            } else {
                history.push('/login')
            }
        })
        .catch((err) => {
            setToastText("Oops!  Something went wrong.  Try again later.")
            setShowToast(true);
        });;
    }
    const handleKeyPress = (e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            return registerUser()        
        }
    }
    const testValidity = () => {
        try {
            if (!isValidEmailAddress(emailAddress)) return setCanSubmit(false)
            if (!password) return setCanSubmit(false)
            if (!password.trim()) return setCanSubmit(false)
            if (password.trim() === "") return setCanSubmit(false)
            if (password.length < 5) return setCanSubmit(false)
            if (!firstName || !lastName) return setCanSubmit(false)
            if (firstName.trim() === "" || lastName.trim() === "") return setCanSubmit(false)
            if (password !== passwordCheck) return setCanSubmit(false)
            return setCanSubmit(true)
        } catch (error) {
            setCanSubmit(false)
        }
    }
    useEffect(() => {
        testValidity()
    // eslint-disable-next-line
    }, [emailAddress, password, passwordCheck, firstName, lastName])
    useEffect(() => {
        if (dataManager.getAuth()) {
            history.push('/home');
        }
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        const backdrop = document.querySelector('.register-backdrop');
        const updateBackgroundColor = () => {
            const nextIndex = (currentIndex + 1) % backgroundShades.length;
            setCurrentIndex(nextIndex);
            const nextBlueShade = backgroundShades[nextIndex];
            const rgbaColor = `rgba(${nextBlueShade.join(',')}, 1)`;
            backdrop.style.backgroundColor = rgbaColor;
        };
        const interval = setInterval(updateBackgroundColor, 15000);
        return () => clearInterval(interval);
    // eslint-disable-next-line
    }, [currentIndex]);
    return (
        <div className="register-container">
            <UnauthenticatedHeader authenticated={false} />
            {showToast && <ToastText content={toastText} setShowToast={setShowToast} />}
            <div className="register-backdrop"></div>
            <div className="register-form">
                <h1>New accounts not available</h1>
                <h3>We're upgrading! Come back later.</h3>
                <div>
                    <div className="register-form-animation-outer">
                        <img src="https://storage.googleapis.com/tetheros-public/landing/register-animation.gif" alt="People partying" />
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import UnauthenticatedHeader from '../header/UnauthenticatedHeader';
import LandingFooter from './LandingFooter';

import { updateHeadTags, updateSchemaOrgJSONLD } from '../../common/Helpers';
import { FaCheck, FaAnglesRight, FaAngleRight, FaPlus, FaMinus, FaXTwitter, FaLinkedin, FaNewspaper } from "react-icons/fa6";
import { IoClose, IoTelescope, IoLeafSharp } from "react-icons/io5";
import { FaLongArrowAltRight } from "react-icons/fa";
import { BsLightningChargeFill } from "react-icons/bs";

import { PRICING_TABLE } from '../../config/Pricing';
import { FAQ } from '../../config/FAQ'

export default function LandingBase({authenticated}) {
    let history = useHistory();
    const location = useLocation()
    const [navigation, setNavigation] = useState(() => { return "home" })
    const [isAnnual, setIsAnnual] = useState(() => { return false })
    const handleToggle = (cycle) => {
        setIsAnnual(cycle);
    };
    const updateNav = () => {
        try {
            if (!location) return
            if (location.pathname === "/pricing") return setNavigation("pricing")
            return setNavigation("home")
        } catch (error) {
            return
        }
    }
    const answerCallToAction = (plan, annual) => {
        history.push(`/register?plan=${plan}&annual=${annual}`)
    }
    // const heroTitle = <div>Simple project management for boutique agencies</div>
    const heroTitle = <div>Simple project management for small teams</div>
    // const heroSubtitle = <div className="landing-base-home-hero-subtitle-decorative">Don't let complicated spreadsheets suffocate your teamwork. Organize every project, process, and pixel with a practical task management framework in Tetheros so you can deliver great work on time and under budget.</div>
    const heroSubtitle = <div className="landing-base-home-hero-subtitle-decorative">Easily track unlimited ideas, projects, and processes with Tetheros to deliver great work on time and under budget.<br/><br/>Zero paywalls or project limits.</div>
    
    const updateHomeMeta = () => {
        try {
            // JSON-LD Data
            const schema = {
                "@context": "http://schema.org",
                "@type": "WebSite",
                "name": "Tetheros",
                "url": "https://www.tetheros.com",
                "logo": "https://storage.googleapis.com/tetheros-public/logo/Tetheros%20OpenGraph%20Image.png",
                "description": "A collaboration platform that helps busy design teams deliver multiple projects efficiently.",
                "publisher": {
                    "@type": "Organization",
                    "name": "Tetheros",
                    "url": "https://www.tetheros.com",
                    "logo": {
                    "@type": "ImageObject",
                    "url": "https://storage.googleapis.com/tetheros-public/logo/Tetheros%20OpenGraph%20Image.png",
                    "width": 1200,
                    "height": 630
                    }
                },
                "sameAs": [
                    "https://www.facebook.com/profile.php?id=100093504467373",
                    "https://twitter.com/tetheros",
                    "https://www.linkedin.com/company/tetheros/"
                ]
            }
            updateSchemaOrgJSONLD(schema)

            // Meta
            let title = "Tetheros | Simplify Your Operations"
            let metaTags = [
                { name: 'description', content: 'A collaboration platform that helps busy design teams deliver multiple projects efficiently.' },
                { name: 'robots', content: 'index, follow' },
                { name: 'og:title', content: title },
                { name: 'og:description', content: 'A collaboration platform that helps busy design teams deliver multiple projects efficiently.' },
                { name: 'og:url', content: 'https://tetheros.com/' },
                { name: 'og:type', content: 'website' },
                { name: 'og:image', content: 'https://storage.googleapis.com/tetheros-public/logo/Tetheros%20OpenGraph%20Image.png' },
                { name: 'twitter:card', content: 'summary_large_image' },
                { name: 'twitter:title', content: title },
                { name: 'twitter:image', content: 'https://storage.googleapis.com/tetheros-public/logo/Tetheros%20OpenGraph%20Image.png' },
                { name: 'twitter:description', content: 'A collaboration platform that helps busy design teams deliver multiple projects efficiently.' },
            ]
            updateHeadTags(title, metaTags)
        } catch (error) {
            updateHeadTags("Tetheros | Simplify Your Operations", [])
        }
    }
    useEffect(() => {
        updateHomeMeta()
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (authenticated === null) return
        if (authenticated) {
            history.push('/home')
            return
        }
        return
    // eslint-disable-next-line
    }, [authenticated])
    useEffect(() => {
        updateNav()
    // eslint-disable-next-line
    }, [location])
    // useEffect(() => {
    //     window.scrollTo({
    //         top: 0,
    //         left: 0,
    //         behavior: 'smooth'
    //     });
    // // eslint-disable-next-line
    // }, [navigation])
    return (
        <div className="landing-base-container">
            {!authenticated &&  <UnauthenticatedHeader/>}
            {navigation === "home" &&
            <div>
                <div className="landing-base-home-hero-texts-outer landing-base-home-hero-texts-outer-main">
                    <div className="landing-base-home-hero-texts">
                            <h1>{heroTitle}</h1>
                            <h3>{heroSubtitle}</h3>
                        <div style={{marginTop: "36px"}}>
                            <div className="landing-base-home-cta" onClick={() => history.push('/register')}>Launch a project<FaLongArrowAltRight/></div>
                            <div className="landing-base-home-cta-watch">7-day trial. No credit card required.</div>
                        </div>
                    </div>

                    <div className="landing-base-home-hero-videos">
                        <div className="landing-base-home-hero-video-container" style={{width: "100%", margin: "auto", marginTop: "40px", cursor: "default", backgroundColor: "unset", boxShadow: "unset", marginBottom: "120px"}}>
                            <video width={1920} height={1080} autoPlay={true} loop={true} muted={true} playsInline={true} style={{height: "auto", borderRadius: "14px"}} src="https://storage.googleapis.com/tetheros-public/landing/2024-07-Tetheros-Quickstart.mp4" type="video/mp4"></video>
                        </div>
                    </div>

                </div>

                <div className="landing-base-primary-container-limited-width" style={{marginBottom: "180px", marginTop: "40px"}}>
                    <div style={{color: "grey", textAlign: "center", marginBottom: "40px"}}>Trusted by teams everywhere</div>
                    <div className="landing-base-primary-social-proof-logos">
                        <img src="https://storage.googleapis.com/tetheros-public/landing/logos/SL-logo-color%20horiz%20construction%200506%202020.png" alt="Shaw-Lundquist Construction" />
                        {/* <img src="https://storage.googleapis.com/tetheros-public/landing/logos/tedxminneapolis-logo-black.jpg" alt="TEDxMinneapolis" /> */}
                        <img src="https://storage.googleapis.com/tetheros-public/landing/logos/armatage-candle-company-logo-icon-darl.png" alt="Armatage Candle Company" />
                        <img src="https://storage.googleapis.com/tetheros-public/landing/logos/moonlite-logo-black.png" alt="MoonLite" />
                        <img src="https://storage.googleapis.com/tetheros-public/landing/logos/comment-pilgrim-logo.png" alt="Comment Pilgrm" />
                    </div>
                </div>

                <div className="landing-base-primary-container-limited-width" style={{marginBottom: "180px", marginTop: "40px"}}>
                    <div className="util-row util-col util-align-center util-justify-center" style={{gap: "120px", width: "100%", marginLeft: "auto", marginRight: "auto"}}>
                        <div className="landing-base-primary-basic-text-section" style={{maxWidth: "800px"}}>
                            <h4>Why Tetheros</h4>
                            <p>Tetheros is a response to complicated platforms & tools that require certifications and training to use effectively.</p>
                            <p>When you're focused on bringing ideas to life you need simplicity.</p>
                            <p>So we replaced:</p>
                            <p style={{lineHeight: 2}}>
                            • Endless customizations with <span>straightforward tools</span><br/>
                            • Pay-for-feature licenses with an <span>unlimited model</span><br/>
                            • Complex dashboards with a <span>personalized feed</span>
                            </p>
                            <p>Tetheros provides a practical framework for visibility and control over assignments and deadlines to help teams build efficient processes for every phase of project delivery.</p>
                        </div>
                    </div>
                </div>
                
                <div style={{width: "100%", maxWidth: "1200px", marginLeft: "auto", marginRight: "auto", padding: "0px 40px", boxSizing: "border-box"}}>
                    <div className="landing-base-primary-basic-text-section" style={{maxWidth: "unset", marginBottom: "80px"}}>
                        <h4>Replace clutter with clarity</h4>
                    </div>

                    <div className="landing-base-home-big-capabilities">
                        <div className="landing-base-home-big-capability">
                            <div className="landing-base-home-big-capability-title-block"><BsLightningChargeFill style={{color: "#FFB901"}}/>Efficiency</div>
                            <p>Eliminate endless back-and-forth emails that slow progress and waste fee. Rapidly kickstart projects, communicate, and organize everything.</p>
                        </div>
                        <div className="landing-base-home-big-capability">
                            <div className="landing-base-home-big-capability-title-block"><IoTelescope style={{color: "#9F6ADB"}} />Clarity</div>
                            <p>Stop guessing who's doing what by when. Permanently illuminate every deadline, assignment, and request across your entire team.</p>
                        </div>
                        <div className="landing-base-home-big-capability">
                            <div className="landing-base-home-big-capability-title-block"><IoLeafSharp style={{color: "#FC636B"}}/>Simplicity</div>
                            <p>Stop feeling lost in complicated or missing processes. Power project delivery on a fast, intuitive, and ridiculously straightforward platform.</p>
                        </div>
                    </div>
                </div>

                <div className="landing-base-primary-container-limited-width util-row util-col" style={{marginBottom: "180px", marginTop: "200px", gap: "180px"}}>
                    <div className="landing-base-home-feature-image-and-text-container" style={{flexDirection: "column"}}>
                        <div className="landing-base-home-feature-text-container">
                            <h4 style={{textAlign: "center"}}>Automatically Plan Projects</h4>
                            <p style={{textAlign: "center"}}><strong>Generate tasks with one click.</strong> Kickstart your next project by with a description of work and Tetheros will automatically create a set of tasks.</p>
                        </div>
                        <div className="landing-base-home-feature-image-container">
                            <video width={1600} height={675} autoPlay={true} loop={true} muted={true} playsInline={true} style={{maxWidth: "100%", height: "auto"}} src="https://storage.googleapis.com/tetheros-public/landing/20240821-Tetheros-Assist-Preview.mp4" type="video/mp4"></video>
                            <p><strong>Generate tasks with one click.</strong> Kickstart your next project by with a description of work and Tetheros will automatically create a set of tasks.</p>
                        </div>
                    </div>

                    <div className="landing-base-home-feature-image-and-text-container reverse">
                        <div className="landing-base-home-feature-text-container">
                            <h4>Stop Chasing Emails</h4>
                            <p><strong>Post, comment and react.</strong> Connect in one place with social conventions you're already used to, like posts, comments, and reactions.<br/><br/>Tetheros is a social workspace platform that combines community and productivity. Store team communications as close the work you're collaborating on already and spend less time searching your inbox for notes and files.</p>
                        </div>
                        <div className="landing-base-home-feature-image-container">
                            <img src="https://storage.googleapis.com/tetheros-public/landing/2024%2008%2021%20Content%20Post.png" alt="Tetheros supports content creation" />
                            <p><strong>Post, comment and react.</strong> Connect in one place with social conventions you're already used to, like posts, comments, and reactions.</p>
                        </div>
                    </div>

                    <div className="landing-base-home-feature-image-and-text-container">
                        <div className="landing-base-home-feature-text-container">
                            <h4>Effortlessly Find Anything</h4>
                            <p><strong>Customize your workspace.</strong> Tune your workspace to fit your team's needs perfectly.<br/><br/>Need more space?<br/><br/>Launch as many workspaces as you need for every team, committee, project, client or <em>whatever</em>. Design the perfect system for organizing work.</p>
                        </div>
                        <div className="landing-base-home-feature-image-container">
                            <video width={800} height={450} autoPlay={true} loop={true} muted={true} playsInline={true} style={{maxWidth: "100%", height: "auto"}} src="https://storage.googleapis.com/tetheros-public/landing/2024-06-26-tetheros-project-portfolio.mp4" type="video/mp4"></video>
                            <p><strong>Customize your workspace.</strong> Tune your workspace to fit your team's needs perfectly..</p>
                        </div>
                    </div>

                </div>

                <div style={{width: "100%", maxWidth: "1200px", marginLeft: "auto", marginRight: "auto", padding: "0px 40px", boxSizing: "border-box"}}>
                    <div className="landing-base-primary-basic-text-section" style={{maxWidth: "unset", marginBottom: "20px"}}>
                        <h4>Frequently Asked Questions</h4>
                    </div>
                    <FAQSection/>
                </div>

                <div style={{width: "100%", maxWidth: "1200px", marginLeft: "auto", marginRight: "auto", padding: "0px 40px", marginTop: "180px", boxSizing: "border-box"}}>
                    <div className="landing-base-primary-basic-text-section" style={{maxWidth: "unset", marginBottom: "20px"}}>
                        <h4>Powerful Tools</h4>
                    </div>
                    <div className="landing-base-home-other-features-desktop"><OtherFeaturesDesktop /></div>
                    <div className="landing-base-home-other-features-mobile"><OtherFeaturesMobile /></div>
                </div>

                

                

                {/* <div style={{marginTop: "80px", paddingBottom: "80px"}}>
                    <div className="landing-base-alt-hero-texts landing-base-pricing" style={{gap: "20px"}}>
                        <div className="util-row util-col util-align-center">
                            <div className="landing-base-home-feature-title">Pricing</div>
                            <div className="landing-base-pricing-limitation-pill">Limited Time Launch Price</div>
                            <div className="landing-base-home-subtext">Unlimited simplicity. Only pay for people, never features.</div>
                        </div>
                        <div>
                            <Toggle onToggle={handleToggle} />
                        </div>
                        <div className="landing-plans-prices">
                            {PRICING_TABLE.map((plan) => (
                                <PricingPlan plan={plan} isAnnual={isAnnual} key={plan._id} answerCallToAction={answerCallToAction} />
                            ))}
                        </div>

                        <div className="util-row util-col" style={{gap: "12px", marginTop: "40px"}}>
                            <div style={{textAlign: "center", fontWeight: 700}}>Tetheros is free to join!</div>
                            <div style={{textAlign: "center"}}>You don't need a paid license to join workspaces or networks created by others.</div>
                        </div>

                    </div>
                </div> */}

                <FoundersNote/>
            </div>
            }

            {navigation === "pricing" &&
            <div>
                <div className="landing-base-alt-hero-texts landing-base-pricing">
                    <div className="util-row util-col util-align-center">
                        <h1>Pricing</h1>
                        <div className="landing-base-pricing-limitation-pill">Limited Time Launch Price</div>
                        <h3>Unlimited simplicity. Only pay for people, never features.</h3>
                    </div>
                    <div>
                        <Toggle onToggle={handleToggle} />
                    </div>
                    <div className="landing-plans-prices">
                        {PRICING_TABLE.map((plan) => (
                            <PricingPlan plan={plan} isAnnual={isAnnual} key={plan._id} answerCallToAction={answerCallToAction} />
                        ))}
                    </div>

                    <div className="util-row util-col" style={{gap: "12px", marginTop: "40px"}}>
                        <div style={{textAlign: "center", fontWeight: 700}}>Tetheros is free to join!</div>
                        <div style={{textAlign: "center"}}>You don't need a paid license to join workspaces or networks created by others.</div>
                    </div>

                </div>
            </div>
            }

            <LandingFooter/>
            
        </div>
    )
}

function PricingPlan({ plan, isAnnual, answerCallToAction }) {
    const featuredPlan = "team"
    const calculatedMonthlyCost = () => {
        let annualCost = plan.cost_monthly * 12
        if (isAnnual) annualCost = plan.cost_annual
        const monthly = Math.round(annualCost/12).toFixed(0)
        const number = parseFloat(monthly);
        return number.toLocaleString();
    }
    const calculatedAnnualCost = () => {
        let annualCost = plan.cost_monthly * 12
        if (isAnnual) annualCost = plan.cost_annual
        const monthly = annualCost
        const number = parseFloat(monthly)
        return number.toLocaleString()
    }
    return (
        <div className={`pricing-plan ${plan._id === featuredPlan ? "featured" : "" }`}>
            {plan._id === featuredPlan && <div className="pricing-plan-badge-featured">Recommended</div> }
            <div className="pricing-plan-header">
                <div className="pricing-plan-name">{plan.name}</div>
                <div className="pricing-plan-subtitle">{plan.subtitle}</div>
            </div>
            <div className="pricing-plan-cost">
                <span className="pricing-plan-cost-amount">${calculatedMonthlyCost()}</span>
                <span className="pricing-plan-cost-period">/month</span>
            </div>
            <span className="pricing-plan-cost-period" style={{fontSize: "12px"}}>${calculatedAnnualCost()} per year</span>
            <div className="pricing-plan-description" style={{marginTop: "12px"}}>Included in {plan.name}</div>
            <div className="pricing-plan-benefits">
                <span>{plan.name !== "Social"  ? <FaCheck /> : <IoClose/>} Unlimited Workspaces</span>
                <span>{plan.name !== "Social"  ? <FaCheck /> : <IoClose/>} Unlimited Tasks</span>
                <span>{plan.name !== "Social"  ? <FaCheck /> : <IoClose/>} Unlimited Projects</span>
                <span>{plan.name !== "Social"  ? <FaCheck /> : <IoClose/>} Unlimited Lists</span>
                <span>{plan.name !== "Social"  ? <FaCheck /> : <IoClose/>} Unlimited Boards</span>
                <span>{plan.name !== "Social"  ? <FaCheck /> : <IoClose/>} Unlimited Timelines</span>
                {/* <span><FaCheck /> Chat</span> */}
                <span>{plan.has_network ? <FaCheck /> : <IoClose/>} Network</span>
                <span>{plan.has_priority_support ? <FaCheck /> : <IoClose/>} Priority Support</span>
                {/* <span>{plan.has_channels ? <FaCheck /> : <IoClose/>} Social Channels</span> */}
                {plan.allowed_network_users > 0 && <span style={{fontWeight: 700}}><FaCheck /> {plan.allowed_network_users} Users</span>}
                {plan.allowed_network_users === 0 && <span><FaCheck /> Social Profile</span>}
            </div>
            <div className="pricing-plan-cta" onClick={() => answerCallToAction(plan._id, isAnnual ? "true" : "false")}>
                {plan.cta_text}
                {plan._id === "startup" && <FaAngleRight/>}
                {plan._id === "business" && <FaAnglesRight/>}
            </div>
        </div>
    );
}

function Toggle({onToggle}) {
    const [isAnnual, setIsAnnual] = useState(false);

    const handleToggle = () => {
        setIsAnnual(!isAnnual);
        onToggle(isAnnual ? false : true);
    };

    return (
        <div className="pricing-toggle">
            <span className="toggle-label">Save with annual billing</span>
            <label className="switch">
                <input type="checkbox" checked={isAnnual} onChange={handleToggle} />
                <span className="slider round"></span>
            </label>
            <span className="save-label">1 Month Free</span>
        </div>
    );
}

const otherFeatures = {
    desk: {
        benefit: "See all your work in one glance",
        name: "My Desk",
        description: "Effortlessly find your personal assignments, notes, and conversations in a single dashboard so you don't have to search in more than one place."
    },
    templates: {
        benefit: "Rapidly start new projects",
        name: "Templates",
        description: "Templates offer a pre-built set of workstreams and tasks. Automate planning and onboarding with templates for every phase of a project or process."
    },
    roster: {
        benefit: "Easy partner management",
        name: "Roster Management",
        description: "Streamline internal and external collaboration by leveraging tools for workspace and permission access so you don't have to give away the keys to the kingdom every time you partner with someone."
    },
    feed: {
        benefit: "Catch up in seconds",
        name: "Workspace Feed",
        description: "What if we collaborated as efficiently as we watch, react, share and shop? Allow the algorithm to provide context to the latest and greatest happenings on your team."
    },
    resources: {
        benefit: "Put an end to poor file management",
        name: "Project Resources",
        description: "Connect tasks and projects with web links or files hosted in your agencies cloud solution so you always have the latest version for context."
    },
    pricing: {
        benefit: "Say no to paywalls",
        name: "Transparent Licensing",
        description: "Tetheros offers an unlimited amount of every feature to every user, no matter what plan you're on. Only pay for more teammates, not features."
    }
}

function OtherFeaturesDesktop() {
    const [otherFeature, setOtherFeature] = useState(() => { return "desk" })
    return (
        <div className="landing-base-home-other-features desktop">
            <div className="landing-base-home-other-features-list-desktop">
                <span className={otherFeature === "desk" ? "active" : ""} onClick={() => setOtherFeature("desk")}>My Desk</span>
                <span className={otherFeature === "templates" ? "active" : ""} onClick={() => setOtherFeature("templates")}>Templates</span>
                <span className={otherFeature === "feed" ? "active" : ""} onClick={() => setOtherFeature("feed")}>Workspace Feed</span>
                <span className={otherFeature === "resources" ? "active" : ""} onClick={() => setOtherFeature("resources")}>Project Resources</span>
                <span className={otherFeature === "roster" ? "active" : ""} onClick={() => setOtherFeature("roster")}>Roster Management</span>
                <span className={otherFeature === "pricing" ? "active" : ""} onClick={() => setOtherFeature("pricing")}>Transparent Licensing</span>
            </div>
            <div className="landing-base-home-other-features-displayed-desktop">
                <h3>{otherFeatures[otherFeature].benefit}</h3>
                <p>{otherFeatures[otherFeature].description}</p>
            </div>
        </div>
    )
}

function OtherFeaturesMobile() {
    const orderedFeatureList = [
        "desk",
        "templates",
        "feed",
        "resources",
        "roster",
        "pricing"
    ]
    return (
        <div className="landing-base-home-other-features">
            <div className="landing-base-home-other-features-list-mobile">
                {orderedFeatureList.map((featureName) => (
                    <div className="landing-base-home-other-features-list-mobile-item" key={featureName}>
                        <div className="util-row util-align-center" style={{gap: "8px", marginBottom: "16px", flexWrap: "wrap"}}>
                            <h3>{otherFeatures[featureName].benefit}</h3>
                            <span>{otherFeatures[featureName].name}</span>
                        </div>
                        <p>{otherFeatures[featureName].description}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

function FAQSection() {
    const [expandedItems, setExpandedItems] = useState({});

    const toggleFAQ = (index) => {
        setExpandedItems(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    return (
        <div className="faq-section">
            {FAQ.map((item, index) => (
                <div key={index} className="faq-item">
                    <div className="faq-question" onClick={() => toggleFAQ(index)}>
                        {item.question}
                        {expandedItems[index] ? <FaMinus /> : <FaPlus />}
                    </div>
                    <div className={`faq-answer ${expandedItems[index] ? 'expanded' : ''}`}>
                        <span>{item.answer}</span>
                    </div>
                </div>
            ))}
        </div>
    );
}

function FoundersNote() {
    return (
        <div className="landing-founders-note-container">
            <div className="landing-base-primary-basic-text-section" style={{maxWidth: "unset", marginBottom: "20px"}}>
                <h4>About</h4>
            </div>
            <div className="landing-founders-note-inner-container">
                <div className="landing-founders-note-headshot-section">
                    <div className="landing-founders-note-headshot">
                        <img src="https://storage.googleapis.com/tetheros-public/landing/20240827KevinAFischerHeadshot.png" alt="Kevin Fischer" />
                    </div>
                    <div className="landing-founder-information">
                        <h5>Kevin Fischer</h5>
                        <p>Founder</p>
                        <div className="landing-founder-information-socials">
                            <a title="X profile" target="_blank" rel="noopener noreferrer" href="https://x.com/iamkevinfischer"><FaXTwitter/></a>
                            <a title="LinkedIn profile" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/kevinalanfischer/"><FaLinkedin/></a>
                            <a title="Newsletter" target="_blank" rel="noopener noreferrer" href="https://www.kevinafischer.com"><FaNewspaper/></a>
                        </div>
                    </div>
                </div>
                <div className="landing-founders-note-content">
                    <p>Kevin started Tetheros in 2022 to make it easier for people to work together.</p>
                    <p>He saw that modern tools meant for productivity were ironically creating busywork. They were too complicated, requiring adminstrators and training to use, and they didn't work for everyone, which created divisions between teams that needed to collaborate.</p>
                    <p>Out of his frustration was born Tetheros - a combination of the words "Tether" and "OS" to signify a way of work that brings people together for a shared purpose.</p>
                    <p>Software can't solve everything, but a strong collaborative platform can make it easy to do the right thing.</p>
                    <p>Tetheros allows teams to work together intuitively on projects and operations large and small without the complexities and temptations of other tools.</p>
                    <p>Efficiency + Clarity + Simplicity → Tetheros</p>
                </div>
            </div>
        </div>
    )
}